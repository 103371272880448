import Icon from "./Icon";
import React from "react";

export default () => {
  return (
    <Icon bg="#fff7f4">
      <g stroke="#ed6135">
        <path
          d="M19.4 30.667h5.2c4.333 0 6.067-1.734 6.067-6.067v-5.2c0-4.333-1.734-6.067-6.067-6.067h-5.2c-4.333
          0-6.066 1.734-6.066 6.067v5.2c0 4.333 1.733 6.067 6.066 6.067ZM17.678 13.333v-2.166M22 13.333v-2.166M26.334
          13.333v-2.166M30.666 17.667h2.167M30.666 22h2.167M30.666 26.333h2.167M26.334 30.667v2.166M22.01
          30.667v2.166M17.678 30.667v2.166M11.166 17.667h2.167M11.166 22h2.167M11.166 26.333h2.167" />
        <path d="m18.061 25.856 2.305-7.375a.1.1 0 0 1 .19 0l2.305 7.375" />
        <path d="M21.901 22.976h-2.88" />
        <path d="M25.508 18.449v7.38" />
      </g>
    </Icon>
  );
};
