import Css from "./style.module.scss";

import { AUTH_URL } from "const";
import { IconBurger, IconClose, IconLogIn } from "lib/icons";
import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";

import MobileMenuCzechRepublic from "./lib/MobileMenuCzechRepublic";
import PopupMenuCzechRepublic from "./lib/PopupMenuCzechRepublic";

const PopupMenuBlock = ({ className }) => {
  return <PopupMenuCzechRepublic className={className} />;
};

const MobileMenuBlock = () => {
  return <MobileMenuCzechRepublic />;
};

const MobileHeader = ({ opened, onClick }) => {
  const signInLabel = "Přihlásit se";

  return (
    <div className={Css.mobileMenu}>
      <div className={Css.menu} onClick={onClick}>
        {opened ? <IconClose /> : <IconBurger />}
      </div>
      <div className={Css.logo}>
        <a href="/" className={Css.logo}>
          <img src="/images/logo-white.svg" />
        </a>
      </div>
      <a className={Css.login} href={AUTH_URL}>
        {signInLabel}
        <IconLogIn />
      </a>
    </div>
  );
};

const HeaderCzechRepublic = ({ index }) => {
  const [detached, setDetached] = useState(false);

  const [menuOpened, setMenuOpened] = useState(false);

  const handleWindowScroll = useCallback(() => {
    setDetached(window.scrollY > 0);
  }, []);

  const handleMenuClick = useCallback(() => {
    setMenuOpened((prev) => !prev);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleWindowScroll);
    handleWindowScroll();

    return () => {
      window.removeEventListener("scroll", handleWindowScroll);
    };
  }, [handleWindowScroll]);

  useEffect(() => {
    if (menuOpened) {
      document.documentElement.classList.add("overflowHidden");
    } else {
      document.documentElement.classList.remove("overflowHidden");
    }
  }, [menuOpened]);

  return (
    <>
      {menuOpened && (
        <div className={Css.offCanvas}>
          <MobileHeader opened={menuOpened} />
          <MobileMenuBlock handleMenuClick={handleMenuClick} />
          <div className={Css.overlay} onClick={handleMenuClick} />
        </div>
      )}
      <div
        className={classNames(
          Css.header,
          index && Css.index,
          detached && Css.detached,
        )}>
        <div className={`container ${Css.container}`}>
          <div className={Css.inner}>
            <a href="/" className={Css.logo}>
              <img src="/images/logo.svg" alt="Booke AI" />
            </a>
            <nav>
              <span className={`${Css.popupItem} ${Css.listItem}`}>
                <span>Pro účetní firmy</span>
                <PopupMenuBlock className={classNames(Css.popupMenu, detached && Css.scrolledPopupMenu)} />
              </span>
              <a className={Css.listItem} href="/zjednoduseni-ucetnictvi-ai">Pro podnikání</a>
              <a className={Css.listItem} href="/cs/pricing">Ceník</a>
              <a className={Css.listItem} href={AUTH_URL} data-left>Přihlásit se</a>
              <a href="/signup" className={classNames(Css.signUp, Css.listItem, Css.bordered)}>Začněte hned</a>
            </nav>
          </div>
        </div>

        <MobileHeader opened={menuOpened} onClick={handleMenuClick} />
      </div>
    </>
  );
};

export default HeaderCzechRepublic;
