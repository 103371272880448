import React from "react";

import Icon from "./Icon";

const IconValid = () => {
  return (
    <Icon viewBox="0 0 256 256">
      <g strokeWidth="24" stroke="#1db173">
        <polyline points="172 104 113.3 160 84 132" />
        <circle cx="128" cy="128" r="96" />
      </g>
    </Icon>
  );
};

export default IconValid;
