import React from "react";

import Icon from "./Icon";

const IconBuildings = () => {
  return (
    <Icon>
      <path
        transform="scale(0.75)"
        d="M1.5 20.248h21m-9 0v-16.5a.75.75 0 0 0-.75-.75h-9a.75.75 0 0 0-.75.75v16.5m18
        0v-10.5a.75.75 0 0 0-.75-.75H13.5M6 6.748h3m-1.5 6h3M6 16.498h3m7.5 0H18m-1.5-3.75H18"
        stroke="#0267D4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </Icon>
  );
};

export default IconBuildings;
