/* eslint-disable react/jsx-no-bind */
import Css from "./styles.module.scss";
import Link from "lib/common/Link";
import React from "react";
import classNames from "classnames";

const preventClickHandler = (event) => {
  event.stopPropagation();
};

const Item = ({ title, url, disabled, bold = false, onClick }) => {
  return (
    <li>
      <Link
        disabled={disabled}
        href={url}
        className={classNames({
          [Css.link]: true,
          [Css.bold]: bold,
          [Css.disabled]: disabled,
        })}
        onClick={disabled ? preventClickHandler : onClick}>
        {title}
      </Link>
    </li>
  );
};

export default Item;
