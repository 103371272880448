import React from "react";

import Icon from "./Icon";

const IconAvailable = () => {
  return (
    <Icon viewBox="0 0 16 16">
      <circle cx="8" cy="8" r="8" fill="#1DB173" stroke="none" />
      <path d="m11.438 5.813-4.376 4.374L4.876 8" stroke="#fff" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    </Icon>
  );
};

export default IconAvailable;
