import Css from "./style.module.scss";

import Category from "./lib/Category";
import IconChat from "./lib/icons/IconChat";
import IconDashboard from "./lib/icons/IconDashboard";
import IconGrid from "./lib/icons/IconGrid";
import IconInvoice from "./lib/icons/IconInvoice";
import IconJournal from "./lib/icons/IconJournal";
import IconTask from "./lib/icons/IconTask";
import Item from "./lib/Item";
import React from "react";
import classNames from "classnames";

const PopupMenuCzechRepublic = ({ className }) => {
  return (
    <div className={classNames(Css.popupMenu, className)}>
      <div className={Css.container}>
        <Category title="Automatizace účetnictví">
          <div className={Css.row}>
            <div className={Css.col}>
              <Item
                icon={IconInvoice}
                title="Zpracování faktur a dokladů"
                description="Vytěžování dat z faktur a dokladů v reálném čase"
                url="/ocr-ai/vytezovani-dat-faktur-dokladu" />
            </div>
            <div className={Css.col}>
              <Item
                icon={IconTask}
                title="Správa Úkolů"
                description={"Snížte interní a externí \n komunikaci vytvářením úkolů"}
                url="/sprava-ukolu-komunikace" />
            </div>
            <div className={Css.col}>
              <Item
                icon={IconChat}
                title="Komunikace"
                description="Vylepšete komunikaci s klienty a zefektivněte spolupráci"
                url="/efektivni-komunikace-ucetni-firmy" />
            </div>
          </div>
        </Category>
        <Category title="Obecné funkce">
          <div className={Css.row}>
            <div className={Css.col}>
              <Item
                icon={IconJournal}
                title="Protokolování Změn v Dokladech"
                description="S naším komplexním systémem záznamů aktivit zajistěte, aby nic nebylo ztraceno nebo zapomenuto"
                url="/protokolovani-zmen-doklady" />
            </div>
            <div className={Css.col}>
              <Item
                icon={IconDashboard}
                title="Komplexní Výkonnostní Panel"
                description="Spravujte a sledujte pokrok všech vašich klientů na jednom pohodlném místě"
                url="/komplexni-vykonnostni-panel" />
            </div>
            <div className={Css.col}>
              <Item
                icon={IconGrid}
                title="Digitální účetní portál"
                description="Vytvořte svůj vlastní digitální účetní portál"
                url="/budte-jako-trivi" />
            </div>
          </div>
        </Category>
      </div>
    </div>
  );
};

export default PopupMenuCzechRepublic;
