import Icon from "./Icon";
import React from "react";

export default () => {
  return (
    <Icon bg="#fff6f8">
      <g stroke="#f3545e">
        <path d="M11.166 32.833h21.667" />
        <path
          d="M19.563 13.333v19.5h4.875v-19.5c0-1.191-.488-2.166-1.95-2.166h-.976c-1.462 0-1.95.975-1.95 2.166ZM12.25
          19.833v13h4.333v-13c0-1.191-.433-2.166-1.733-2.166h-.867c-1.3 0-1.733.975-1.733 2.166ZM27.416
          25.25v7.583h4.334V25.25c0-1.192-.433-2.167-1.733-2.167h-.867c-1.3 0-1.733.975-1.733 2.167Z" />
      </g>
    </Icon>
  );
};
