import Category from "./lib/Category";
import Css from "./styles.module.scss";
import Item from "./lib/Item";
import React from "react";
import ToggleItem from "./lib/ToggleItem";

const MobileMenuCzechRepublic = ({ handleMenuClick }) => {
  return (
    <nav className={Css.nav}>
      <ToggleItem title="Pro účetní firmy">
        <Category title="Automatizace účetnictví">
          <Item title="Zpracování faktur a dokladů" url="/ocr-ai/vytezovani-dat-faktur-dokladu" />
          <Item title="Správa Úkolů" url="/sprava-ukolu-komunikace" />
          <Item title="Komunikace" url="/efektivni-komunikace-ucetni-firmy" />
        </Category>
        <Category title="Obecné funkce">
          <Item title="Protokolování Změn v Dokladech" url="/protokolovani-zmen-doklady" />
          <Item title="Komplexní Výkonnostní Panel" url="/komplexni-vykonnostni-panel" />
          <Item title="Digitální účetní portál" url="/budte-jako-trivi" />
        </Category>
      </ToggleItem>
      <ul>
        <Item onClick={handleMenuClick} title="Pro podnikání" url="/zjednoduseni-ucetnictvi-ai" bold />
        <Item onClick={handleMenuClick} title="Ceník" url="/cs/pricing" bold />
      </ul>
    </nav>
  );
};

export default MobileMenuCzechRepublic;
