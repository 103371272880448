import Css from "./style.module.scss";

import React from "react";

const TopBanner = () => {
  return (
    <div className={Css.topBanner}>
      <span><b>NEW:</b> <span>Apply to the</span> <a href="/become-a-partner">Booke AI Partner Program</a></span>
    </div>
  );
};

export default TopBanner;
