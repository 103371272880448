import React from "react";

import Icon from "./Icon";

const IconPhone = () => {
  return (
    <Icon
      d="M6.5 8.8a6 6 0 0 0 2.7 2.7.6.6 0 0 0 .6 0l1.8-1.2a.6.6 0 0 1
      .5 0l3.3 1.4a.6.6 0 0 1 .3.5 3.4 3.4 0 0 1-3.3 3 9.6 9.6 0 0
      1-9.6-9.6 3.4 3.4 0 0 1 3-3.3.6.6 0 0 1 .5.3L7.8 6a.6.6 0 0 1 0 .5L6.4 8.2a.6.6 0 0 0 0 .6Z" />
  );
};

export default IconPhone;
