import Category from "./lib/Category";
import Css from "./styles.module.scss";
import Item from "./lib/Item";
import React from "react";
import ToggleItem from "./lib/ToggleItem";

const MobileMenu = ({ handleMenuClick }) => {
  return (
    <nav className={Css.nav}>
      <ul>
        <Item onClick={handleMenuClick} title="Robotic AI Bookkeeper" url="/robotic-ai-bookkeeper" bold />
      </ul>
      <ToggleItem title="Bookkeeping Automation">
        <Category title="Fix Uncategorized Transactions">
          <Item title="Auto-categorization" url="/auto-categorization" />
          <Item title="Intelligent AI-assistant" url="/intelligent-ai-assistant" />
          <Item title="Client Query Tool" url="/client-query-tool" />
          <Item title="Two-way integrations" url="/two-way-integrations" />
          <Item title="Invoice & Receipt OCR AI" url="/ocr-ai/invoice-and-receipt" />
          <Item title="Accounts Payable workflow" url="/accounts-payable-workflow" />
        </Category>
        <Category title="Month-End Close">
          <Item title="Inconsistencies and error detection" url="/inconsistencies-and-error-detection" />
          <Item
            disabled
            title="Interactive Reports"
            url="#" />
          <Item
            disabled
            title="Trend Analysis"
            url="#" />
        </Category>
        <Category title="Booke Chrome Extension">
          <Item title="Xero" url="/xero-reconciliation" />
          <Item disabled title="QuickBooks" url="#" />
          <Item disabled title="Audit Insights" url="#" />
        </Category>
        <Category title="General features">
          <Item title="Tasks" url="/tasks" />
          <Item title="Activities Journal" url="/changes-logging" />
          <Item title="Performance Dashboard" url="/performance-dashboard" />
        </Category>
      </ToggleItem>
      <ul>
        <Item onClick={handleMenuClick} title="Prices" url="/pricing" bold />
        <Item onClick={handleMenuClick} title="Testimonials" url="/testimonials" bold />
      </ul>
    </nav>
  );
};

export default MobileMenu;
