import React from "react";

const DEFAULT_WIDTH = 44;

export default ({ bg = "#fbfbfb", width = DEFAULT_WIDTH, children }) => {
  return (
    <svg viewBox={`0 0 ${width} ${width}`} xmlns="http://www.w3.org/2000/svg">
      {/* eslint-disable-next-line no-magic-numbers */}
      <rect width={width} height={width} rx={DEFAULT_WIDTH / 2} fill={bg} />
      <g strokeWidth="1.5">{children}</g>
    </svg>
  );
};
