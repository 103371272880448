import React from "react";

import Icon from "./Icon";

const IconInvalid = () => (
  <Icon viewBox="0 0 256 256">
    <g stroke="#f3545e">
      <g strokeWidth="24">
        <circle cx="128" cy="128" r="96" />
        <line x1="128" y1="80" x2="128" y2="132" />
      </g>
      <circle cx="128" cy="172" r="16" fill="#f3545e" />
    </g>
  </Icon>
);

export default IconInvalid;
