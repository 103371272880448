/* eslint-disable react/jsx-no-bind */
import Css from "./styles.module.scss";
import React, { useState } from "react";
import classNames from "classnames";

const ToggleItem = ({ title, children }) => {
  const [active, setActive] = useState(false);

  const onClickHandler = (event) => {
    event.stopPropagation();
    setActive((prev) => !prev);
  };

  return (
    <ul className={Css.toggleItem}>
      <li onClick={onClickHandler} className={Css.title}>{title}</li>
      <ul
        onClick={onClickHandler}
        className={classNames(Css.list, { [Css.active]: active })}>
        {children}
      </ul>
    </ul>
  );
};

export default ToggleItem;
