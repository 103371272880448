import React from "react";

const IconMapPin = () => {
  return (
    <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 10.063a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5Z"
        stroke="#0267D4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M14.625 7.813c0 5.062-5.625 9-5.625 9s-5.625-3.938-5.625-9a5.625 5.625 0 0 1 11.25 0Z"
        stroke="#0267D4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default IconMapPin;
