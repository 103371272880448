import React from "react";

import Icon from "./Icon";

const IconLogIn = () => {
  return (
    <Icon viewBox="0 0 256 256">
      <g strokeWidth="24">
        <polyline points="94 170 136 128 94 86" />
        <line x1="24" y1="128" x2="136" y2="128" />
        <path d="M136,40h56a8,8,0,0,1,8,8V208a8,8,0,0,1-8,8H136" />
      </g>
    </Icon>
  );
};

export default IconLogIn;
