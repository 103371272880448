import React from "react";

import Icon from "./Icon";

const PATHS = [
  "M1.125 15.186h15.75M10.124 15.186V2.811a.563.563 0 0 0-.562-.562h-6.75a.563.563 0 0 0-.562.562v12.375",
  `M15.75 15.186V7.311a.563.563 0 0 0-.563-.562h-5.063M4.5
    5.061h2.25M5.625 9.561h2.25M4.5 12.374h2.25M12.374 12.374H13.5M12.374 9.561H13.5`,
];

const IconBuilding = () => (<Icon paths={PATHS} />);

export default IconBuilding;
