import Icon from "./Icon";
import React from "react";

export default () => {
  return (
    <Icon bg="#fff7f8">
      <g stroke="#f3545e">
        <path
          d="M32.834 15.5v2.622c0 1.711-1.084 2.795-2.796 2.795h-3.704v-7.573c0-1.202.985-2.177 2.188-2.177
          1.18.01 2.264.487 3.044 1.267a4.36 4.36 0 0 1 1.268 3.066Z" />
        <path
          d="M11.166 16.583V31.75a1.08 1.08 0 0 0 1.734.867l1.852-1.387a1.091 1.091 0 0 1 1.43.108l1.799
          1.81a1.092 1.092 0 0 0 1.538 0l1.82-1.82c.38-.38.986-.423 1.408-.098l1.853 1.387c.715.53 1.733.021
          1.733-.867V13.333c0-1.191.975-2.166 2.167-2.166h-13c-3.25 0-4.334 1.939-4.334 4.333v1.083Z" />
        <path
          d="M18.75 23.094H22M18.75 18.76H22"
          strokeWidth="2" />
        <path
          d="M15.495 23.083h.01M15.495 18.75h.01"
          strokeWidth="2"
          strokeLinecap="round" />
      </g>
    </Icon>
  );
};

