import React from "react";

import Icon from "./Icon";

const IconCaretUp = () => (
  <Icon viewBox="0 0 256 256">
    <polyline
      points="48 160 128 80 208 160"
      stroke="currentColor"
      strokeWidth="24" />
  </Icon>
);

export default IconCaretUp;
