import Css from "./styles.module.scss";
import React, { useCallback, useState } from "react";
import classNames from "classnames";

const Category = ({ title, children }) => {
  const [active, setActive] = useState(false);

  const onClickHandler = useCallback((event) => {
    event.stopPropagation();
    setActive((prev) => !prev);
  }, []);

  return (
    <>
      <li
        onClick={onClickHandler}
        className={Css.title}>
        {title}
      </li>
      <ul className={classNames(Css.list, { [Css.active]: active })}>
        {children}
      </ul>
    </>
  );
};

export default Category;
