import React from "react";

import Icon from "./Icon";

const IconCaretDown = () => (
  <Icon viewBox="0 0 256 256">
    <polyline
      points="208 96 128 176 48 96"
      stroke="currentColor"
      strokeWidth="24" />
  </Icon>
);

export default IconCaretDown;
