import Icon from "./Icon";
import React from "react";

export default () => {
  return (
    <Icon bg="#f9f5fe">
      <g stroke="#9550ed">
        <path
          d="M31.208 21.242v-4.615c0-4.366-1.018-5.46-5.113-5.46h-8.19c-4.095 0-5.114 1.094-5.114 5.46v12.198c0 2.882
          1.582 3.564 3.5 1.506l.01-.01c.889-.943 2.243-.868 3.012.162l1.094 1.462" />
        <path
          d="M28.717 32.183a3.467 3.467 0 1 0 0-6.933 3.467 3.467 0 0 0 0 6.933ZM32.833 32.833 31.75 31.75M17.666
          16.583h8.667M18.75 20.917h6.5" />
      </g>
    </Icon>
  );
};
