import Icon from "./Icon";
import React from "react";

export default () => {
  return (
    <Icon bg="#f4f9ff">
      <g stroke="#0267d2">
        <path
          d="M11.166 25.25a7.578 7.578 0 0 0 7.584 7.583l-1.138-1.895M32.833 18.75a7.578 7.578 0 0 0-7.583-7.583l1.137 1.896" />
        <path
          d="M21.996 23.581a1.95 1.95 0 1 0 0-3.898 1.95 1.95 0 0 0 0 3.898Z" />
        <path
          d="M15.5 22.204v-1.143c0-.676.552-1.235 1.234-1.235 1.176 0 1.657-.831 1.066-1.851a1.234 1.234 0 0
          1 .455-1.683l1.124-.643a1.084 1.084 0 0 1 1.48.39l.072.123c.585 1.02 1.547 1.02 2.138 0l.071-.123a1.084
          1.084 0 0 1 1.482-.39l1.123.643c.592.338.793 1.098.455 1.683-.59 1.02-.11 1.851 1.066 1.851.675 0 1.234.552
          1.234 1.235v1.143c0 .676-.552 1.234-1.234 1.234-1.176 0-1.657.832-1.066 1.852a1.232 1.232 0 0
          1-.454 1.683l-1.124.643a1.084 1.084 0 0 1-1.482-.39l-.071-.123c-.585-1.02-1.546-1.02-2.138 0l-.071.123a1.084
          1.084 0 0 1-1.481.39l-1.124-.643a1.234 1.234 0 0 1-.455-1.683c.591-1.02.11-1.852-1.066-1.852a1.238 1.238 0 0
          1-1.234-1.234Z" />
      </g>
    </Icon>
  );
};

