import Icon from "./Icon";
import React from "react";

export default () => {
  return (
    <Icon bg="#fff7f8">
      <path
        d="M22 19.563v4.062M20.594 13.06l-8.936 15.438a1.626 1.626 0 0 0 1.407 2.439h17.87a1.625 1.625 0 0 0
        1.407-2.44l-8.936-15.436a1.624 1.624 0 0 0-2.812 0v0Z"
        stroke="#f3545e" />
      <path
        d="M22 28.5a1.219 1.219 0 1 0 0-2.438 1.219 1.219 0 0 0 0 2.438Z"
        fill="#f3545e" />
    </Icon>
  );
};
