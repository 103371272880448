import Icon from "./Icon";
import React from "react";

export default () => {
  return (
    <Icon bg="#efffed">
      <path
        d="M11.083 27.02a7.085 7.085 0 0 0 5.028 2.097h.999v-2.824h-1v.002a4.294 4.294 0 0 1-3.046-1.27 4.356 4.356 0 0
        1-1.263-3.07c0-1.151.454-2.255 1.262-3.07a4.294 4.294 0 0 1 3.048-1.27v.002h2.1V31.07a2.92 2.92 0 0 0 2.91
        2.93V14.793h-5.01a7.085 7.085 0 0 0-5.028 2.098A7.188 7.188 0 0 0 9 21.955c0 1.9.75 3.721 2.083 5.064ZM32.917
        16.98a7.085 7.085 0 0 0-5.028-2.097h-.999v2.824h1v-.002c1.142 0 2.238.457 3.046 1.27a4.356 4.356 0 0 1 1.263
        3.07 4.356 4.356 0 0 1-1.262 3.07 4.294 4.294 0 0 1-3.048 1.27v-.002h-2.1V12.93a2.92 2.92 0 0
        0-2.91-2.93v19.207h5.01a7.085 7.085 0 0 0 5.028-2.098A7.188 7.188 0 0 0 35 22.045c0-1.9-.75-3.721-2.083-5.064Z"
        fill="#2c9f1c" />
    </Icon>
  );
};
