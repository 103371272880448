import Icon from "./Icon";
import React from "react";

export default () => {
  return (
    <Icon bg="#f4f9ff">
      <path
        d="M17.666 11.167v3.25M26.334 11.167v3.25M16.584 23.083h8.666M16.584 27.417H22M26.333 12.792c3.608.195 5.417
        1.57 5.417 6.662v6.695c0 4.464-1.083 6.695-6.5 6.695h-6.5c-5.417 0-6.5-2.231-6.5-6.695v-6.695c0-5.091 1.81-6.456
        5.417-6.662h8.666Z"
        stroke="#0267d2" />
    </Icon>
  );
};
