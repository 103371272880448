import Icon from "./Icon";
import React from "react";

export default () => {
  return (
    <Icon bg="#f2fff9">
      <g stroke="#1db173">
        <path
          d="M18.718 32.833h6.5c5.416 0 7.583-2.166 7.583-7.583v-6.5c0-5.417-2.167-7.583-7.583-7.583h-6.5c-5.417 0-7.584
        2.166-7.584 7.583v6.5c0 5.417 2.167 7.583 7.584 7.583Z" />
        <path
          d="m11.134 22.758 6.5-.021c.813 0 1.723.617 2.026 1.376l1.235 3.12c.282.704.726.704 1.008
        0l2.48-6.295c.239-.606.683-.628.986-.054l1.127 2.134c.336.64 1.202 1.16 1.917 1.16h4.399"
          stroke="#1DB173" />
      </g>
    </Icon>
  );
};
