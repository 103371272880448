import React from "react";

import Icon from "./Icon";

const IconGift = () => {
  return (
    <Icon viewBox="0 0 24 24" d="M19.5 12v6.8a.7.7 0 0 1-.8.7H5.3a.7.7 0 0 1-.8-.8V12M12 7.5v12m0-12s3.2 0 4.2-1a2.2 2.2 0 0 0-3.1-3.2C12 4.3 12 7.5 12 7.5Zm0 0s-3.2 0-4.2-1a2.2 2.2 0 0 1 3.1-3.2C12 4.3 12 7.5 12 7.5Zm-8.3 0h16.6c.4 0 .7.3.7.7v3c0 .5-.3.8-.8.8H3.9a.8.8 0 0 1-.8-.8v-3c0-.4.3-.7.8-.7Z" />
  );
};

export default IconGift;
