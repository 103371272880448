import Icon from "./Icon";
import React from "react";

export default () => {
  return (
    <Icon bg="#fffdef">
      <path
        d="M20.916 30.125H31.75M20.916 22.542H31.75M20.916 14.958H31.75M12.25 14.958l1.083 1.084 3.25-3.25M12.25
        22.542l1.083 1.083 3.25-3.25M12.25 30.125l1.083 1.083 3.25-3.25"
        stroke="#F3D434" />
    </Icon>
  );
};

