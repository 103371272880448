import Css from "../style.module.scss";

import React from "react";

import { AUTH_URL, CALENDLY_WIDGET_URL } from "const";

const FooterCz = () => {
  return (
    <div className={Css.footer}>
      <div className="container">
        <div className={Css.main}>
          <div className={Css.logo}>
            <img src="/images/logo-white.svg" />
          </div>
          <div className="row-m">
            <div className={Css.colFirst}>
              <div className={Css.textLarge}>Rádi děláme nudnou část účetnictví, abyste to nemuseli dělat vy.</div>
            </div>
            <div className={Css.colSecond}>
              <div className={Css.title}>Zajímá vás, jak to funguje?</div>
              <a href={CALENDLY_WIDGET_URL} className={`button block ${Css.button}`}>Požádat o demo</a>
            </div>
            <div className={Css.colThird}>
              <div className={Css.title}>Máte již účet?</div>
              <a href={AUTH_URL} className={`button stroke block ${Css.button}`}>Přihlásit se</a>
            </div>
          </div>
        </div>
        <div className={Css.sub}>
          <div className={Css.col}>
            <div className={Css.title}>Lorem 1</div>
            <a href="/terms-and-conditions">Obchodní podmínky</a>
            <a href="/privacy-policy">Ochrana osobních údajů</a>
            <a href="/cookie-policy">Používání Cookies</a>
            <a href="/hub">Resource Hub</a>
          </div>
          <div className={Css.col}>
            <div className={Css.title}>Lorem 1</div>
            <a href="/terms-and-conditions">Obchodní podmínky</a>
            <a href="/privacy-policy">Ochrana osobních údajů</a>
            <a href="/cookie-policy">Používání Cookies</a>
            <a href="/hub">Resource Hub</a>
          </div>
          <div className={Css.col}>
            <div className={Css.title}>Lorem 1</div>
            <a href="/terms-and-conditions">Obchodní podmínky</a>
            <a href="/privacy-policy">Ochrana osobních údajů</a>
            <a href="/cookie-policy">Používání Cookies</a>
            <a href="/hub">Resource Hub</a>
          </div>
          <div className={Css.col}>
            <div className={Css.title}>Lorem 1</div>
            <a href="/terms-and-conditions">Obchodní podmínky</a>
            <a href="/privacy-policy">Ochrana osobních údajů</a>
            <a href="/cookie-policy">Používání Cookies</a>
            <a href="/hub">Resource Hub</a>
          </div>
          <div className={Css.col}>
            <div className={Css.title}>Lorem 1</div>
            <a href="/terms-and-conditions">Obchodní podmínky</a>
            <a href="/privacy-policy">Ochrana osobních údajů</a>
            <a href="/cookie-policy">Používání Cookies</a>
            <a href="/hub">Resource Hub</a>
          </div>
          <div className={Css.copyright}>
            {`Booke AI Inc. © ${new Date().getFullYear()} All rights reserved`}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterCz;
