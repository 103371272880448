import React from "react";

import Icon from "./Icon";

const IconBurger = () => {
  return (
    <Icon viewBox="0 0 24 24">
      <path d="M3 6h18M3 12h18M3 18h18" strokeWidth="2" />
    </Icon>
  );
};

export default IconBurger;
