import Css from "./style.module.scss";

import Category from "./lib/Category";
import IconAi from "./lib/icons/IconAi";
import IconAttention from "./lib/icons/IconAttention";
import IconAudit from "./lib/icons/IconAudit";
import IconChat from "./lib/icons/IconChat";
import IconDashboard from "./lib/icons/IconDashboard";
import IconGear from "./lib/icons/IconGear";
import IconGrid from "./lib/icons/IconGrid";
import IconInvoice from "./lib/icons/IconInvoice";
import IconJournal from "./lib/icons/IconJournal";
import IconList from "./lib/icons/IconList";
import IconQbo from "./lib/icons/IconQbo";
import IconTask from "./lib/icons/IconTask";
import IconTrend from "./lib/icons/IconTrend";
import IconWorkflow from "./lib/icons/IconWorkflow";
import IconXero from "./lib/icons/IconXero";
import Item from "./lib/Item";
import React from "react";
import classNames from "classnames";

const PopupMenu = ({ className }) => {
  return (
    <div className={classNames(Css.popupMenu, className)}>
      <div className={Css.container}>
        <Category title="Fix Uncategorized Transactions">
          <div className={Css.row}>
            <div className={Css.col}>
              <Item
                icon={IconGrid}
                title="Auto-Categorization"
                description="80% faster transactions categorization"
                url="/auto-categorization" />
              <Item
                icon={IconAi}
                title="Intelligent AI-assistant"
                description="Get AI-driven expert reconciliation assistance and suggestions"
                url="/intelligent-ai-assistant" />
            </div>
            <div className={Css.col}>
              <Item
                icon={IconChat}
                title="Client Query Tool"
                description="Ask clients about transactions with just one click."
                url="/client-query-tool" />
              <Item
                icon={IconGear}
                title="Two-way Integrations"
                description="Seamlessly connect to Xero, QuickBooks or Zoho Books"
                url="/two-way-integrations" />
            </div>
            <div className={Css.col}>
              <Item
                icon={IconInvoice}
                title="Invoice & Receipt OCR AI"
                description="Extract data from invoices, bills and receipts in real-time"
                url="/ocr-ai/invoice-and-receipt" />
              <Item
                icon={IconWorkflow}
                title="Accounts Payable workflow"
                description="Import and easily manage documents in bulk"
                url="/accounts-payable-workflow" />
            </div>
          </div>
        </Category>
        <div className={Css.row}>
          <div className={Css.col}>
            <Category title="Month-End Close">
              <Item
                icon={IconAttention}
                title="Inconsistencies Detection"
                description="Easily find and fix discrepancies in the books"
                url="/inconsistencies-and-error-detection" />
              <Item
                icon={IconTrend}
                title="ChatGPT for Email Inbox"
                description="Effortlessly transforms your client emails into tasks, streamlining your bookkeeping journey"
                url="chat-gpt" />
              <Item
                disabled
                icon={IconList}
                title="Interactive Reports"
                description="Generate and share beautiful interactive reports with just one click"
                url="#" />
            </Category>
          </div>
          <div className={Css.col}>
            <Category title="Booke Chrome Extension">
              <Item
                twoLinesDescription
                icon={IconXero}
                title="Xero"
                description="Reconcile categorized transactions in bulk directly in Xero with just one click"
                url="/xero-reconciliation" />
              <Item
                disabled
                icon={IconQbo}
                title="QuickBooks"
                description="Mark unknown bank feed transactions as uncategorized with just one click"
                url="#" />
              <Item
                disabled
                icon={IconAudit}
                title="Audit Insights"
                description="Get discrepancy insights directly in QuickBooks and Xero"
                url="#" />
            </Category>
          </div>
          <div className={Css.col}>
            <Category title="General Features">
              <Item
                icon={IconTask}
                title="Tasks"
                description={"Reduce internal and external \n communication by creating tasks"}
                url="/tasks" />
              <Item
                icon={IconJournal}
                title="Activities Journal"
                description="Ensure nothing is lost or forgotten with our comprehensive activity logging system"
                url="/changes-logging" />
              <Item
                icon={IconDashboard}
                title="Performance Dashboard"
                description="Manage and check the progress of all your clients in one convenient place"
                url="/performance-dashboard" />
            </Category>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupMenu;
