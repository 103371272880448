import React from "react";

import Icon from "./Icon";

const IconBriefCase = () => {
  return (
    <Icon
      d="M11.812 5.063V3.938a1.125 1.125 0 0 0-1.125-1.126H7.312a1.125
      1.125 0 0 0-1.125 1.126v1.124m9.563 3.82A13.438 13.438 0 0 1 9
      10.687c-2.37.003-4.699-.62-6.75-1.806m5.906-.444h1.687m-7.03-3.376h12.375c.31 0
      .562.252.562.563v9c0 .31-.252.563-.562.563H2.813a.562.562 0 0 1-.563-.563v-9c0-.31.252-.563.563-.563Z" />
  );
};

export default IconBriefCase;
