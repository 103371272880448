import Icon from "./Icon";
import React from "react";

export default () => {
  return (
    <Icon bg="#fffdf1">
      <path
        stroke="#f3d434"
        d="M26.4 20.24h1.761c1.76 0 2.64-.88 2.64-2.641v-1.76c0-1.76-.88-2.641-2.64-2.641h-1.76c-1.76
        0-2.64.88-2.64 2.64V17.6c0 1.76.88 2.64 2.64 2.64ZM15.838 30.802h1.76c1.761 0 2.641-.88
        2.641-2.64v-1.76c0-1.761-.88-2.642-2.64-2.642h-1.76c-1.761 0-2.641.88-2.641 2.641v1.76c0
        1.761.88 2.641 2.64 2.641ZM16.719 20.24a3.52 3.52 0 1 0 0-7.042 3.52 3.52 0 0 0 0 7.042ZM27.281
        30.802a3.52 3.52 0 1 0 0-7.042 3.52 3.52 0 0 0 0 7.042Z" />
    </Icon>
  );
};

