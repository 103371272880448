import Css from "./style.module.scss";

import React from "react";

const Category = ({ title, children }) => {
  return (
    <div className={Css.category}>
      <div className={Css.categoryTitle}>
        {title}
      </div>
      <div className={Css.items}>
        {children}
      </div>
    </div>
  );
};

export default Category;
