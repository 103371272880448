/* eslint-disable react/jsx-no-bind */
import { useRouter } from "next/router";
import Css from "./styles.module.scss";
import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";

const ToggleItem = ({ title, children }) => {
  const { pathname } = useRouter();

  const listRef = useRef();

  const [active, setActive] = useState(false);

  const onClickHandler = (event) => {
    event.stopPropagation();
    setActive((prev) => !prev);
  };

  useEffect(() => {
    try {
      listRef.current.querySelectorAll("a").forEach((node) => {
        if (node.pathname === pathname) setActive(true);
      });
    } catch (exception) {}
  }, [pathname]);

  return (
    <ul className={Css.toggleItem}>
      <li onClick={onClickHandler} className={Css.title}>{title}</li>
      <ul
        ref={listRef}
        onClick={onClickHandler}
        className={classNames(Css.list, { [Css.active]: active })}>
        {children}
      </ul>
    </ul>
  );
};

export default ToggleItem;
