import Icon from "./Icon";
import React from "react";

export default () => {
  return (
    <Icon bg="#ecfcf5">
      <g stroke="#1db173">
        <path d="m19.632 24.377 1.32 1.32 3.521-3.521" />
        <path
          d="M20.24 16.719h3.52c1.76 0 1.76-.88 1.76-1.76 0-1.761-.88-1.761-1.76-1.761h-3.52c-.88 0-1.761 0-1.761
          1.76s.88 1.76 1.76 1.76Z" />
        <path
          d="M25.52 14.976c2.932.158 4.402 1.24 4.402 5.263v5.282c0 3.52-.88 5.281-5.281 5.281h-5.282c-4.4
          0-5.28-1.76-5.28-5.281v-5.282c0-4.013 1.47-5.105 4.4-5.263" />
      </g>
    </Icon>
  );
};
