import React from "react";

import Icon from "./Icon";

const IconCheck = () => {
  return (
    <Icon>
      <path d="m2.667 8.21 3.77 3.77 7.543-7.542" />
    </Icon>
  );
};

export default IconCheck;
