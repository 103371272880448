import { useRouter } from "next/router";
import Css from "./styles.module.scss";
import React, { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";

const Category = ({ title, children }) => {
  const { pathname } = useRouter();

  const listRef = useRef();

  const [active, setActive] = useState(false);

  const onClickHandler = useCallback((event) => {
    event.stopPropagation();
    setActive((prev) => !prev);
  }, []);

  useEffect(() => {
    try {
      listRef.current.querySelectorAll("a").forEach((node) => {
        if (node.pathname === pathname) setActive(true);
      });
    } catch (exception) {}
  }, [pathname]);

  return (
    <>
      <li
        onClick={onClickHandler}
        className={Css.title}>
        {title}
      </li>
      <ul className={classNames(Css.list, { [Css.active]: active })} ref={listRef}>
        {children}
      </ul>
    </>
  );
};

export default Category;
