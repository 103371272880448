import Icon from "./Icon";
import React from "react";

export default () => {
  return (
    <Icon bg="#f9f5fe">
      <g stroke="#9550ed">
        <path
          d="m27.695 26.252.343 2.78c.088.732-.695 1.242-1.32.863l-3.688-2.191c-.405 0-.8-.027-1.188-.08a4.279
          4.279 0 0 0 1.038-2.78c0-2.5-2.165-4.525-4.84-4.525-1.022 0-1.964.29-2.747.8a5.581 5.581 0 0
          1-.035-.668c0-4.005 3.477-7.253 7.772-7.253 4.296 0 7.772 3.248 7.772 7.253 0 2.377-1.223 4.48-3.107 5.8Z" />
        <path
          d="M22.88 24.843a4.279 4.279 0 0 1-1.039 2.782c-.871 1.056-2.253 1.734-3.802 1.734l-2.297
          1.364c-.388.238-.88-.088-.828-.537l.22-1.734c-1.18-.819-1.936-2.13-1.936-3.609 0-1.55.827-2.913
          2.095-3.723a5.002 5.002 0 0 1 2.746-.801c2.676 0 4.841 2.024 4.841 4.524Z" />
      </g>
    </Icon>
  );
};
