import Css from "./style.module.scss";

import React from "react";

const Item = ({ icon: Icon, title, description, url, disabled }) => {
  return (
    <a href={url} disabled={disabled} className={Css.item}>
      <div className={Css.icon}>{Icon ? <Icon /> : null}</div>
      <div className={Css.text}>
        <div className={Css.title}>{title}</div>
        <div className={Css.description}>
          {description}
        </div>
      </div>
    </a>
  );
};

export default Item;
