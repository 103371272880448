import Css from "./style.module.scss";

import React from "react";

import Document from "./lib/Document";
import Footer from "./lib/Footer";
import Header from "./lib/Header";
import classNames from "classnames";

import { COUNTRY_TO_LOCALE } from "const";
import { useRouter } from "next/router";

import HeaderCzechRepublic from "./lib/HeaderCzechRepublic";
import TopBanner from "./lib/TopBanner";

const HeaderBlock = ({ index, lang }) => {
  const { locale } = useRouter();

  switch (lang || locale) {
    case COUNTRY_TO_LOCALE.CZ:
      return <HeaderCzechRepublic index={index} />;
    default:
      return <Header index={index} />;
  }
};

const Layout = ({ index, lang, className, children, ...restProps }) => {
  return (
    <Document {...restProps}>
      <div id="main" />
      <TopBanner />
      <HeaderBlock lang={lang} index={index} />
      <div className={classNames(Css.layout, className)}>
        {children}
        <Footer />
      </div>
    </Document>
  );
};

export default Layout;
