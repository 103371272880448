/* eslint-disable react/jsx-no-bind */
import Css from "./styles.module.scss";
import React from "react";
import classNames from "classnames";

const Item = ({ title, url, disabled, bold = false, onClick }) => {
  const preventClickHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  if (disabled) {
    return (
      <li>
        <a
          onClick={preventClickHandler}
          className={classNames({ [Css.disabled]: disabled })} href={url}>
          {title}
        </a>
      </li>
    );
  }

  return (
    <li>
      <a onClick={onClick} className={classNames({ [Css.bold]: bold })} href={url}>{title}</a>
    </li>
  );
};

export default Item;
