import Css from "../style.module.scss";

import React from "react";

import { AUTH_URL, CALENDLY_WIDGET_URL } from "const";

const FooterEn = () => {
  return (
    <div className={Css.footer}>
      <div className="container">
        <div className={Css.main}>
          <div className={Css.logo}>
            <img src="/images/logo-white.svg" />
          </div>
          <div className="row-m">
            <div className={Css.colFirst}>
              <div className={Css.textLarge}>We love doing the boring part of bookkeeping, so you don&apos;t have to.</div>
            </div>
            <div className={Css.colSecond}>
              <div className={Css.title}>Curious how it works?</div>
              <a href={CALENDLY_WIDGET_URL} className={`button block ${Css.button}`}>Request a Demo</a>
            </div>
            <div className={Css.colThird}>
              <div className={Css.title}>Already have an account?</div>
              <a href={AUTH_URL} className={`button stroke block ${Css.button}`}>Sign In</a>
            </div>
          </div>
        </div>
        <div className={Css.sub}>
          <div className={Css.col}>
            <div className={Css.title}>Booke.AI</div>
            <a href="/terms-and-conditions">Terms and Conditions</a>
            <a href="/privacy-policy">Privacy Policy</a>
            <a href="/cookie-policy">Cookie Policy</a>
            <a href="/become-a-partner">Become a Partner</a>
          </div>
          <div className={Css.col}>
            <div className={Css.title}>Resources</div>
            <a href="/bookkeeping-ai">AI Bookkeeping</a>
            <a href="/bookkeeping-automation-demo">Bookeeping Automation</a>
            <a href="/simplify-your-bookkeeping">Collaborative Bookkeeping</a>
            {/* <a href="/quickbooks-desktop">QuickBooks Desktop</a>
            <a href="/quickbooks-online">QuickBooks Online</a>
            <a href="/zoho-books">Zoho Books</a> */}
            <a href="/hub">Resource Hub</a>
          </div>
          {/* <div className={Css.col}>
            <div className={Css.title}>Features</div>
            <a href="/terms-and-conditions">Terms and Conditions</a>
            <a href="/privacy-policy">Privacy Policy</a>
          </div> */}
          <div className={Css.col}>
            <div className={Css.title}>Compare</div>
            {/* <a href="/compare/docyt-vs-bookeai-the-future-of-ai-in-accounting">vs Botkeeper</a>
            <a href="/compare/docyt-vs-bookeai-the-future-of-ai-in-accounting">vs Xennet</a>
            <a href="/compare/docyt-vs-bookeai-the-future-of-ai-in-accounting">vs Keeper</a>
            <a href="/compare/docyt-vs-bookeai-the-future-of-ai-in-accounting">vs Uncat</a> */}
            <a href="/compare/dext-vs-booke.ai-which-is-better">vs Dext</a>
            {/* <a href="/compare/dext-vs-booke.ai-which-is-better">vs AutoEntry</a> */}
            <a href="/compare/docyt-vs-bookeai-the-future-of-ai-in-accounting">vs Docyt</a>
          </div>
          {/* <div className={Css.col}>
            <div className={Css.title}>Browser Extensions</div>
            <a href="/simplify-your-bookkeeping">For Xero</a>
            <a href="/terms-and-conditions">For QuickBooks Online</a>
          </div> */}
        </div>
        <div className={Css.copyright}>
          {`Booke AI Inc. © ${new Date().getFullYear()} All rights reserved`}
        </div>
      </div>
    </div>
  );
};

export default FooterEn;
