import { useRouter } from "next/router";
import Link from "next/link";
import React from "react";

export default React.memo(({ onClick, children, href, disabled, ...props }) => {
  const { pathname } = useRouter();

  return (
    <Link href={disabled ? {} : (href || {})}>
      <a
        {...props}
        disabled={disabled}
        data-active={pathname === href ? "" : undefined}
        href={disabled ? undefined : href}
        onClick={onClick} >{children}</a>
    </Link>
  );
});
