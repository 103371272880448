import React from "react";

import { COUNTRY_TO_LOCALE } from "const";
import { useRouter } from "next/router";
import FooterCz from "./FooterCz";
import FooterEn from "./FooterEn";

const Footer = () => {
  const { locale } = useRouter();

  switch (locale) {
    case COUNTRY_TO_LOCALE.CZ:
      return <FooterCz />;
    default:
      return <FooterEn />;
  }
};

export default Footer;
