import Icon from "./Icon";
import React from "react";

export default () => {
  return (
    <Icon bg="#f1fcff">
      <g fill="#13b5ea">
        <path
          d="M16.494 21.221a3.257 3.257 0 0 1 3.161-2.445c1.501 0 2.794 1.003 3.165
          2.445h-6.326Zm7.474.875c.212-.26.291-.6.224-.958-.276-1.298-.983-2.337-2.048-3.007a4.6 4.6 0 0
          0-2.466-.707c-.974 0-1.902.292-2.682.845a4.631 4.631 0 0 0-1.948 3.775c0 .377.046.75.139 1.111a4.659
          4.659 0 0 0 3.923 3.439 4.399 4.399 0 0 0 1.659-.113c.49-.117.956-.314 1.386-.586.407-.262.781-.616
          1.175-1.11l.026-.026a.773.773 0 0 0 .167-.575.642.642 0 0 0-.245-.44.715.715 0 0 0-.43-.152c-.146
          0-.36.053-.552.303l-.016.02c-.064.085-.13.173-.205.26-.26.29-.56.53-.889.712-.471.25-.98.38-1.512.383-1.67
          -.018-2.693-1.125-3.092-2.153a3.676 3.676 0 0 1-.137-.511l-.003-.049 6.467-.001c.449-.01.825-.173
          1.059-.46ZM33.391 20.795c-.65 0-1.177.525-1.177 1.17 0 .644.528 1.17 1.177 1.17.648 0 1.175-.526
          1.175-1.17 0-.645-.527-1.17-1.175-1.17ZM29.102 18.158a.67.67 0 0 0-.672-.666l-.189-.003c-.573
          0-1.116.175-1.572.508a.667.667 0 0 0-1.3.203l.003 7.674a.665.665 0 0 0 1.332 0v-4.72c0-1.529.132-2.168
          1.463-2.332.11-.013.228-.014.259-.014.391-.014.676-.287.676-.65Z" />
        <path
          d="m11.554 22.014 3.45-3.447a.648.648 0 0 0 .196-.467.669.669 0 0 0-1.145-.466l-3.451 3.43-3.465-3.437A.67.67
          0 0 0 6 18.1c0 .178.071.347.201.475l3.453 3.436-3.448 3.44A.654.654 0 0 0 6 25.93c0 .367.3.665.667.665a.68.68
          0 0 0 .472-.192L10.6 22.96l3.446 3.427a.67.67 0 0 0 1.154-.457.662.662 0 0 0-.195-.469l-3.451-3.446ZM33.39
          25.18a3.228 3.228 0 0 1-3.233-3.216 3.23 3.23 0 0 1 3.233-3.22c1.78 0 3.23 1.445 3.23 3.22a3.227 3.227 0 0
          1-3.23 3.217Zm0-7.806c-2.542 0-4.61 2.059-4.61 4.59 0 2.53 2.068 4.59 4.61 4.59 2.542 0 4.61-2.06 4.61-4.59
          0-2.531-2.068-4.59-4.61-4.59Z" />
      </g>
    </Icon>
  );
};
