import Icon from "./Icon";
import React from "react";

export default () => {
  return (
    <Icon bg="#f2fff9">
      <g stroke="#1db173">
        <path
          d="M29.583 26.333V16.042a2.173 2.173 0 0 0-2.166-2.167H22.54" />
        <path
          d="M25.25 11.167 22 13.875l3.25 2.708M14.416 18.75v7.583M14.687 18.208a3.52 3.52 0 1 0 0-7.041
          3.52 3.52 0 0 0 0 7.041ZM14.416 32.833a3.25 3.25 0 1 0 0-6.5 3.25 3.25 0 0 0 0 6.5ZM29.584 32.833a3.25
          3.25 0 1 0 0-6.5 3.25 3.25 0 0 0 0 6.5Z" />
      </g>
    </Icon>
  );
};

